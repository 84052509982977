/* eslint-disable import/no-unresolved -- Legacy! Please clean this up and correct the errors when you edit the file! */
export default {
	start() {
		window.app = this;

		function loadInit() {
			import("~/hooks/init").then(({ default: init }) => init());
		}

		if (document.readyState === "interactive" || document.readyState === "complete") {
			loadInit();
			return;
		}
		window.addEventListener("DOMContentLoaded", loadInit);
	},
};
